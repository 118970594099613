.michelangelo-theme {
	data-configuration {
		page-with-sidebar {
			div.container {
				div.main-content {
					mat-card.mat-card {
						mat-toolbar.mat-toolbar {
							display: block;
							margin-bottom: 20px;
							mat-toolbar-row.mat-toolbar-row {
								min-height: inherit;
								font-size: 0.93rem;
								font-weight: 400;
								font-family: 'Roboto';
							}
						}
					}
				}
			}
		}
	}
}
