@import 'var';

button,
a {
	&.custom-button {
		&.mat-button-base {
			border-radius: 56px;
		}

		&.mat-button,
		&.mat-raised-button,
		&.mat-stroked-button,
		&.mat-flat-button {
			padding: 0 $gutter * 2;

			&,
			& span.mat-button-wrapper {
				// altezza del button
				line-height: $gutter * 3;
			}
		}
	}
}
