.michelangelo-theme{
	/* regole per la formattazione del mat-expansion-panel */
	mat-expansion-panel.mat-expansion-panel {
		border-bottom: solid 1px $border-gray;
		margin: 0; // 15px;
		border-radius: 0 !important;
		mat-expansion-panel-header.mat-expansion-panel-header {
			padding: 0;
			height: 60px;
			span.mat-expansion-indicator::after {
				content: url("data:image/svg+xml,%3Csvg width='6' height='42' viewBox='0 0 6 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.06042 0.39561C2.18255 0.270396 2.32851 0.170887 2.48968 0.102945C2.65086 0.0350024 2.82401 4.93766e-07 2.99892 5.24349e-07C3.17384 5.54932e-07 3.34698 0.0350025 3.50816 0.102945C3.66934 0.170887 3.8153 0.270396 3.93743 0.39561L5.84944 2.79561C6.27244 3.32761 5.74944 3.99561 4.91043 3.99561L1.08841 3.9956C0.249409 3.9956 -0.272594 3.32761 0.149408 2.79561L2.06042 0.39561Z' fill='%23CCCCCC'/%3E%3C/svg%3E%0A");
				color: transparent;
				transform: rotate(180deg);
				border: 0;
				padding: 0;
			}
		}

		.mat-expansion-panel-content {
			.mat-expansion-panel-body {
				padding: 0;
				margin-bottom: 10px;

				div.btn > button {
					margin-top: 8px;
					padding-left: 0;
				}
			}
		}
	}

	mat-expansion-panel.mat-expansion-panel.user-detail {
		border-bottom: 0;
		margin: 0;
	}

	.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover {
		background: transparent;
	}
}

@media screen and (max-width: 991px) {
	.michelangelo-theme {
		mat-expansion-panel.mat-expansion-panel {
			.mat-expansion-panel-content {
				.mat-expansion-panel-body {
					padding: 0;

					div.btn > button {
						padding: 0 10px 0 0;
						span.mat-button-wrapper {
							mat-icon.mat-icon {
								width: 30px;
							}
							span {
								overflow: hidden !important;
								text-overflow: ellipsis !important;
								white-space: nowrap !important;
								max-width: 80px !important;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.michelangelo-theme {
		mat-expansion-panel.mat-expansion-panel {
			div.mat-expansion-panel-content {
				div.mat-expansion-panel-body {
					mat-form-field.mat-form-field {
						margin-bottom: 20px;
						div.mat-form-field-wrapper {
							border: solid 1px $border-gray;
							padding: 10px;
							border-radius: 5px;

							div.mat-form-field-flex {
								div.mat-form-field-infix {
									border: 0;
									mat-select.mat-select:not(.mat-select-empty)
									+ span.mat-form-field-label-wrapper
									label {
										display: none;
									}
								}
							}
						}

						div.mat-form-field-underline {
							display: none;
						}
					}

					mat-form-field.mat-form-field.mat-focused.mat-form-field-should-float {
						div.mat-form-field-wrapper {
							div.mat-form-field-flex {
								div.mat-form-field-infix {
									span.mat-form-field-label-wrapper {
										label {
											display: none;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}