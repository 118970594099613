.compatibility-rules {
	preview-container {
		&.avatar, &.profile {
			.preview-container {
				background-color: #e5e5e5;
				border-radius: 10px;
				overflow: hidden;
				display: flex;
				justify-content: center;

				img {
					display: block !important;
					width: 100% !important;
					height: 100% !important;
					object-fit: cover !important;
					aspect-ratio: 1 / 1 !important;
				}
			}

			mat-icon {
				flex-shrink: 1;
				height: auto;
				object-fit: cover;
				aspect-ratio: 1 / 1;
			}
		}

		&.profile {
			mat-icon {
				width: 120px;
				height: 120px;
				font-size: 28px;
				&::before {
					width: 28px !important;
					height: 100% !important;
				}
			}
		}
	}
}