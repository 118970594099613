@import '@saep-ict/angular-core/src/styles/mixins';

/*
*  override START
*/


/*
*  extend START
*/
/// list-reset - add properties to reset the list style
@mixin reset-list {
	margin: 0;
	padding: 0;
	list-style: none;
}

/// text-decoration - it create an animation
/// @param {string} $color [$primary] -  on hover text color and line color
@mixin text-decoration($color: $primary) {
	$animation-time: 0.3s;

	position: relative;
	text-decoration: none;
	transition: color $animation-time;

	&:after {
		content: '';
		position: absolute;
		display: block;
		bottom: 0;
		left: 0;
		width: 0;
		height: 2px;
		background: $color;
		transition: $animation-time all ease-in-out;
	}

	&:hover,
	&:active,
	&.active {
		color: $color;

		&:after {
			width: 100%;
		}
	}
}

/*
* /// inputNumberHideArrow - hide arrow in input type number
* /// @include inputNumberHideArrow();
*/
@mixin inputNumberHideArrow() {
	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type='number'] {
		-moz-appearance: textfield;
	}
}

/// sectionBottomPositionGenerator
/// genera le classi `.*-section-bottom-right` e `.*-section-bottom-left`
/// aventi relative proprietà di floating
@mixin sectionBottomPositionGenerator($prefix) {
	@each $key in ('right', 'left') {
        .#{$prefix}-section-bottom-#{$key} {
            float: #{$key};
            margin: $gutter 0 0 0;
        }
	}
}