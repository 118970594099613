.mat-raised-button {
	&.mat-primary {
		font-weight: 600;
		letter-spacing: 0.5px;
	}
}

// Search button storefront
.nav-primary__search-field {
	.mat-form-field-wrapper {
		margin: 0;
		padding: 0;

		.mat-form-field-flex {
			.mat-form-field-infix {
				padding-bottom: 10px;
				padding-top: 14px;

				display: flex;
				border: 0;
			}
		}
	}
}

.mat-menu-item {
	a {
		text-decoration: none;
	}
}
