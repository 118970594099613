.michelangelo-theme {
	mat-icon,
	.mat-icon {
		display: inline-flex;
		align-items: center;
		width: 40px;
		height: 40px;
		justify-content: center;
		margin: 0;
		font-size: 16px;
		padding: 0;
		flex-shrink: 0;

		svg {
			width: 16px;
			height: 16px;
			font-size: 16px;
			line-height: 16px;
		}
	}

	mat-icon.bordered,
	.mat-icon.bordered {
		border: 1px solid $border-gray;
		color: $text-gray-light;
		fill: $text-gray-light;
	}

	mat-icon.filled,
	.mat-icon.filled {
		background-color: $primary;
		color: #fff;
		fill: #fff;
		border: 0;
	}



	organization-list-wrapper {
		mat-icon.mat-icon.add-order {
			@include mat-icon-custom();
			@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-inbox.svg');
		}
	}

	mat-icon.mat-icon.download {
		margin-right: 5px;
	}

	mat-icon.mat-icon.download,
	mat-icon.mat-icon.person {
		margin: 0;
		@include mat-icon-custom(16px, 14px);
	}

	//mat-icon.video_library {
	//	margin: 0;
	//	@include mat-icon-custom(20px, 20px);
	//	@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-video-library.svg');
	//}
	//
	//mat-icon.mat-icon.movie {
	//	margin: 0;
	//	@include mat-icon-custom(20px, 20px);
	//	@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-movie.svg');
	//}
	//
	//mat-icon.mat-icon.fullscreen {
	//	margin: 0;
	//	@include mat-icon-custom(20px, 20px);
	//	@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-fullscreen.svg');
	//}
	//
	//mat-icon.mat-icon.fullscreen_exit {
	//	margin: 0;
	//	@include mat-icon-custom(20px, 20px);
	//	@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-fullscreen_exit.svg');
	//}
	//
	//mat-icon.mat-icon.account_balance {
	//	margin: 0;
	//	@include mat-icon-custom(20px, 20px);
	//	@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-account-balance.svg');
	//}

	mat-icon.mat-icon.delete {
		margin: 0;
		@include mat-icon-custom(20px, 20px);
		@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-trash.svg');
	}

	mat-icon.mat-icon.check_circle {
		margin: 0;
		@include mat-icon-custom(20px, 20px);
		@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-check_circle.svg');
	}

	mat-icon.mat-icon.attach_file {
		margin: 0;
		@include mat-icon-custom(20px, 20px);
		@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-attach.svg');
	}
	mat-icon.mat-icon.comment {
		margin: 0;
		@include mat-icon-custom(20px, 20px);
		@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-comment.svg');
	}


	mat-icon.mat-icon.image {
		margin: 0;
		@include mat-icon-custom(20px, 20px);
		@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-picture.svg');
	}

	mat-icon.mat-icon.download {
		@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-download.svg');
	}

	mat-icon.mat-icon.person {
		@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-person.svg');
	}
}
