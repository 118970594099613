.michelangelo-theme{
	news-list-wrapper {
		//.mat-checkbox .mat-checkbox-ripple {
		//	display: none !important;
		//}

		mat-checkbox.mat-checkbox.select-all {
			margin-left: 0 !important;
		}
	}

	news-detail {
		form.michelangelo-theme {
			margin-top: 0;
		}
		form-button-bar {
			display: inherit;
			margin-top: 0;
		}

		@include mat-date-picker-toggle();
	}
}

@media screen and (max-width: 991px) {
	.michelangelo-theme {
		news-list-wrapper {
			mat-toolbar.mat-toolbar {
				mat-toolbar-row.mat-toolbar-row.search-wrapper {
					td-search-box {
						.mat-form-field-infix {
							//max-width: 160px;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.michelangelo-theme {
		news-list-wrapper {
			mat-toolbar.mat-toolbar {
				mat-toolbar-row.mat-toolbar-row.search-wrapper {
					margin-top: 0;
					margin-bottom: 10px;

					td-search-box {
						width: 100%;
					}
				}
			}
		}
	}
}