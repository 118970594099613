.michelangelo-theme {
	// override material paginator
	.mat-paginator {
		display: inline-block;
		margin-top: 20px;

		.mat-paginator-container {
			justify-content: center;
			min-height: auto;
			padding: 0;
			flex-direction: row-reverse;
		}

		// override material paginator page switch
		.mat-paginator-range-label {
			order: 2;
			margin: 0 0 0 15px;
		}

		.mat-paginator-navigation-previous {
			order: 1;
			justify-content: center;
		}
		.mat-paginator-navigation-next {
			order: 3;
			justify-content: center;
		}

		.mat-paginator-page-size-label {
			display: none;
		}

		.mat-select-arrow {
			color: $icon-light-gray;
		}

		.mat-paginator-page-size {
			margin:  0 0 0 20px;
		}


		mat-form-field.mat-form-field.mat-paginator-page-size-select {
			margin: 0;
			max-width: 90px;
			width: auto;
			div.mat-form-field-wrapper {
				border: solid 1px $border-gray;
				padding: 10px 20px;
				border-radius: 5px;

				div.mat-form-field-flex {
					div.mat-form-field-infix {
						border: 0;
						mat-select.mat-select:not(.mat-select-empty) + span.mat-form-field-label-wrapper label {
							display: none;
						}
					}
				}
			}

			div.mat-form-field-underline {
				display: none;
			}
		}
	}
}