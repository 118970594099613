@import '@saep-ict/angular-core/src/styles/var-base';

/*
*  override START
*/

$primary: #66cc33;
$secondary: #888888;
$accent: #ff4400;

/*
* override new layout
*/

$primary-text-color: #000;
$light-gray: #f9f9f9;
$dark-gray: #f0f0f0;
$border-gray: #ebebeb;
$icon-light-gray: #ccc;
$icon-gray: #999999;
$icon-gray-light: #ccc;
$text-gray: #333333;
$text-gray-light: #999999;

$border-button: #000;
$border-disabled-button: #ccc;
$border-divider: #e5e5e5;

$background-main-color: #f9f9f9;

$alert-button-content-color: $light;
$warn-button-content-color: #fafafa;

$input-disabled-background: #efefef;

/*
*  extend START
*/

// navigation
$pre-header-height: 40px;
$header-height: 80px;
$navbar-width-medium: 50vw;
$navbar-width-small: 100vw;

// usato in base-page per generare le classi
// .fixed-#{$v}-#{$h}
$list-pos-vert: 'top' 'bottom';
$list-pos-hor: 'right' 'left';

// variabili dipendenti da assets copiati durante la build in assets/theme-current/*
// editabili nel progetto presso frontend/src/assets-themes/* per ogni tema gestito

// logo
$path-logo-base: 'theme-current/img/company-logo/';
$path-logo: $path-logo-base + 'logo.svg';
$path-logo-footer: $path-logo-base + 'logo-footer.svg';

//size
$gap: 16px;