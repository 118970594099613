.filter-input {
	width: 100%;
	padding: 8px 8px 0 8px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);

	position: sticky !important;
	top: 0;
	z-index: 1;
	background-color: white;

	&.hidden {
		display: block;
		visibility: hidden;
		height: 0;
		width: 0;
	}
}
