.michelangelo-theme {
	category-list-wrapper {
		td-data-table {
			table.td-data-table{
				thead.td-data-table-head {
					tr {
						border: 0;
						th.td-data-table-column {
							text-align: right;
							&:first-of-type {
								text-align: left;
							}
						}
					}
				}
				tbody.td-data-table-body {
					tr.td-data-table-row {
						border-bottom: solid 1px $border-gray;
						&:last-of-type {
							border: 0;
						}
						td.td-data-table-cell {
							div.td-data-table-cell-content-wrapper {
								justify-content: flex-end;
								gap: 10px;

								button.mat-icon-button {
									margin-left: 5px;
									width: auto;
									.mat-button-wrapper {
										mat-icon.mat-icon {
											width: auto;
											@include mat-icon-custom(16px, 14px);
										}

										mat-icon.mat-icon.delete {
											@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-trash.svg');
										}

										mat-icon.mat-icon.image {
											@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-picture.svg');
										}

										mat-icon.mat-icon.edit {
											@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-pencil.svg');
										}
									}
								}
							}
							&:first-of-type {
								div.td-data-table-cell-content-wrapper {
									justify-content: flex-start;
								}
							}
						}
					}
				}
				td, td.td-data-table {
					vertical-align: middle;
				}
			}
		}
	}
}