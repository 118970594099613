.michelangelo-theme {
	/**
    /* formattazione componente context-code-management-detail (backoffice -> gestione context code
    /* prevede principalmente l'aggiunta di un bordo di separazione tra ogni context
    /* la modifica delle icone di eliminazione e apertura/chiusura riga
    /* modifica grafica dei pulsanti di action
    /* e alcuni fix per colori, font e allineamenti
    **/
	context-code-management-detail,
	context-code-management-wrapper,
	.user-detail-context-code {
		table {
			thead {
				tr {
					border-bottom: 0;
				}
			}
			tbody.td-data-table-body {
				tr.td-data-table-row {
					border-width: 1px;
					border-color: $border-gray;
					td.td-data-table-cell {
						vertical-align: middle;
						div.td-data-table-cell-content-wrapper {
							//padding-right: 0 !important;
							button.mat-icon-button {
								width: auto;
								span.mat-button-wrapper {
									mat-icon.mat-icon {

										@include mat-icon-custom(16px, 24px);
										@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-trash.svg');
									}
								}
							}
						}
					}
				}

				tr.td-data-table-row:last-child {
					border: 0;
				}
			}
		}
	}
}

@media screen and (max-width: 991px) {
	.michelangelo-theme {
		context-code-management-detail {
			form-button-bar {
				button {
					span.mat-button-wrapper {
						overflow: hidden !important;
						text-overflow: ellipsis !important;
						white-space: nowrap !important;
						max-width: 105px !important;
						//width: 120px;
						display: block !important;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.michelangelo-theme {
		context-code-management-detail {
			table {
				tbody.td-data-table-body {
					tr.td-data-table-row {
						td.td-data-table-cell {
							div.td-data-table-cell-content-wrapper {
								font-size: 0.73rem;
								overflow: hidden !important;
							}
						}
					}
				}
			}
		}
	}
}