.michelangelo-theme {
	authentication-wrapper {
		.background {
			.half.black {
				background-color: $primary !important;
			}
		}

		.title-container {
			h3 {
				color: #fff !important;
			}
		}

		.form-container {
			> mat-card.mat-card {
				box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);

				password-update {
					password-verify {
						mat-icon {
							@include mat-icon-custom(16px, 14px);
						}

						form.michelangelo-theme {
							.row {
								.col-2 {
									display: none;
								}

								.col-10 {
									width: 100%;
									max-width: 100%;
									flex: 0 0 100%;
									padding: 0;

									mat-form-field {
										margin: 0 0 20px 0;

										.mat-form-field-wrapper {
											.mat-form-field-flex {
												align-items: center;
												.mat-form-field-infix {
													padding: 5px 0;
												}

												.mat-form-field-suffix {
													height: 14px;
													mat-icon {
														width: auto;
														height: auto;
														@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-visibility.svg');

														&.hide {
															@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-visibility_off.svg');
														}
													}
												}
											}
										}
									}
								}
							}
						}

						password-meter {
							.password-meter {
								.password-meter__row {
									.password-meter__row__bar {
										.password-meter__row__bar__segment {
											height: 5px;
											border-radius: 5px;
										}
									}
								}

								&__excellent {
									.password-meter__row {
										.password-meter__row__bar {
											.password-meter__row__bar__segment {
												background-color: rgba(85, 221, 187, 1);
											}
										}
									}
								}

								.password-meter__dialog {
									margin: 5px 3px;
								}
							}

							.ruleset {
								color: $text-gray;
								margin: 30px 0;

								.ruleset__optional {
									margin: 20px 0 20px 24px;
									color: $text-gray-light;
								}

								.ruleset__row {
									margin-top: 10px;
									.ruleset__row__icon {
										height: 16px;
										mat-icon {
											width: auto;
											height: 16px;
											@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-check_circle.svg');
											&.success {
												&::before {
													background-color: rgba(85, 221, 187, 1);
												}
											}
										}
									}
								}
							}

						}
					}
				}
			}
		}
	}

	mat-form-field.auth-wrapper.filter-input {
		margin-top: 0px;
		padding-top: 0;
		border: 0;
		.mat-form-field-wrapper {
			padding: 0;
			.mat-form-field-underline {
				display: none;
			}
			.mat-form-field-flex {
				align-items: center;
				.mat-form-field-infix {
					border: 0;

					input + .mat-form-field-label-wrapper {
						top: -12px;
					}

					input + .mat-form-field-label-wrapper label {
						color: $text-gray-light;
					}
				}

				.mat-form-field-suffix {
					button {
						width: inherit;
						mat-icon.mat-icon {
							color: $icon-gray;
						}
					}
					> mat-icon.mat-icon {
						@include mat-icon-custom();
						margin: 0 10px 0 5px;
						@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-search.svg');
					}
				}
			}
		}

		.mat-form-field-flex {
			flex-direction: row-reverse !important;
		}
	}

	mat-form-field.auth-wrapper.filter-input ~ mat-option.mat-option,
	mat-form-field.auth-wrapper.filter-input ~ mat-option.mat-option:hover {
		background-color: #fff;
		padding-left: 40px;
		.mat-ripple.mat-option-ripple {
			display: none;
		}
	}
}