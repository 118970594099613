@import 'var';

app-root {
	.mat-card {
		height: 100%;
		.mat-card-title {
			display: flex;
		}
		.mat-card-content {
			margin-bottom: 0;
		}
		.mat-card-footer {
			margin: 0;
			padding-top: 10px;
			// lo style seguente applica nel caso venga usata mat-select in una mat-card-footer
			.mat-form-field {
				margin-bottom: -1.25em;
			}
			button {
				margin-left: 15px;
			}
		}
		// lo style seguente applica nel caso venga usata mat-toolbar come titolo di una card
		.mat-toolbar:first-child {
			@include font-size($font-size-card-title);
			.mat-toolbar-row {
				padding: 0;
			}
		}
		mat-icon {
			color: $gray;
		}
		button[class*='mat-fab-button-section-bottom-'] {
			mat-icon {
				color: $white;
			}
		}
	}
}

.cards {
	mat-card {
		margin-bottom: 16px;
	}
}
