.michelangelo-theme {
	/**
		/* regole per la gestione "responsive" del componente tab-wrapper.
		**/
	tab-wrapper {
		::-webkit-scrollbar {
			width: 0px;
			border-radius: 1px;
			height: 0px;
		}

		::-webkit-scrollbar-thumb {
			width: 0px;
			height: 0px;
			border-radius: 1px;
			background: rgba(0, 0, 0, 0);
		}

		.header {
			height: auto;
			width: 100%;
			/* overflow-y: hidden; */
			overflow-x: auto;
			scroll-snap-type: x mandatory;
			//padding: 0 25px;
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			ul {
				flex-wrap: unset !important;
				display: flex;
				flex-grow: 1;
			}
		}
	}



	/* regole per la formattazione del mat-tab-group */
	mat-tab-group.mat-tab-group {
		mat-tab-header.mat-tab-header {
			border-bottom: 0;

			button {
				display: none;
			}

			.mat-tab-label-container {
				.mat-tab-list {
					mat-ink-bar.mat-ink-bar {
						height: 3px;
					}
					.mat-tab-labels {
						gap: 40px;
						.mat-tab-label {
							padding: 0;
							min-width: unset;
							height: 36px;

							.mat-ripple-element {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}
