.compatibility-rules {
	ngx-slider.ngx-slider {
		.ngx-slider-pointer {
			width: 22px;
			height: 22px;
			top: -8px;
			border-radius: 50%;
		}

		.ngx-slider-pointer.ngx-slider-active::after {
			background-color: $accent;
		}

		.ngx-slider-pointer::after {
			width: 8px;
			height: 8px;
			top: 7px;
			left: 7px;
			border-radius: 50%;
		}

		.ngx-slider-pointer.ngx-slider-pointer-max,
		.ngx-slider-pointer.ngx-slider-pointer-min {
			background-color: #000;
		}

		.ngx-slider-span.ngx-slider-bar-wrapper.ngx-slider-selection-bar {
			.ngx-slider-span.ngx-slider-bar.ngx-slider-selection {
				background-color: #000;
			}
		}
	}
}