.michelangelo-theme {
	article-list-structure-wrapper-accordion,
	article-list-structure-backoffice-forecast-wrapper-accordion {
		mat-accordion.mat-accordion {
			mat-expansion-panel.mat-expansion-panel {
				box-shadow: none !important;
				padding: 0 10px;
				.mat-expansion-panel-content {
					.mat-expansion-panel-body {
						margin-bottom: 0px !important;
						padding-top: inherit;
						article-table-wrapper {
							.article-table-wrapper-base.articleColumnMapB2bForecast {
								.rows-wrapper {
									.content-wrapper {
										&:last-of-type:not(:first-of-type) {
											border-bottom: 0;
										}
									}
								}
							}
						}

						.forecast-wrapper {
							.list-row {
								padding-top: 0px;
							}

							.chart-wrapper {
								padding-top: 0px;
							}
						}
					}
				}
				&.mat-expanded {
					background-color: $light-gray;
				}
			}
		}
	}

	article-list-structure-wrapper-plain-list {
		mat-toolbar.mat-toolbar {
			padding: 0;
			mat-toolbar-row.mat-toolbar-row {
				padding: 0;
			}
		}
	}
}