.michelangelo-theme {
	.mat-radio-button {
		.mat-radio-outer-circle {
			border-color: $border-gray !important;
		}

		.mat-ripple {
			display: none;
		}

		&.mat-radio-disabled {
			.mat-radio-outer-circle {
				background-color: $input-disabled-background !important;
			}
		}
	}
}
