.michelangelo-theme {
	aside {
		.side-content {
			.content-wrapper {
				.mat-card {
					.mat-divider,
					.mat-divider.mat-divider-horizontal {
						border: 0;
						border-top: solid 1px $border-divider;
						position: inherit;
						width: 100%;
						margin: 20px 0;
					}

					form {
						display: flex;
						flex-direction: column;
						gap: 5px;
					}

					mat-radio-group.mat-radio-group {
						display: flex;
						flex-direction: column;
						gap: 10px;
					}
				}
			}
		}
	}
}