.michelangelo-theme {
	mc-media-center {
		mat-icon.mat-icon.folder,
		mat-icon.mat-icon.color_folder,
		mat-icon.mat-icon.photo,
		mat-icon.mat-icon.text_fields,
		mat-icon.mat-icon.description,
		mat-icon.mat-icon.all_inbox,
		mat-icon.mat-icon.download,
		mat-icon.mat-icon.attach_file{

			@include mat-icon-custom(20px, 20px);
		}

		mat-icon.mat-icon.folder {
			@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-folder-2.svg');
		}

		mat-icon.mat-icon.color_folder {
			@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-folder-colored.svg');
			&::before {
				background-color: #f8c342 !important;
			}
		}

		mat-icon.mat-icon.description {
			@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-description.svg');
		}

		mat-icon.mat-icon.photo {
			@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-image.svg');
		}

		mat-icon.mat-icon.text_fields {
			@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-text_fields.svg');
		}

		mat-icon.mat-icon.all_inbox {
			@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-all_inbox.svg');
		}

		mat-icon.mat-icon.download {
			@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-download.svg');
		}

		mat-icon.mat-icon.attach_file {
			@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-attach.svg');
		}
	}

	media-center {
		mc-media-center {
			media-center-home, media-center-latest {
				td-data-table {
					table {
						thead {
							tr.td-data-table-column-row {
								th.mat-checkbox-column {
									@include mat-checkbox();
								}
							}
						}
						tbody {
							tr:hover,
							tr:focus,
							tr.td-selected {
								background-color: transparent !important;
							}

							tr {
								td.mat-checkbox-cell {
									.td-data-table-cell-content-wrapper {
										width: min-content;
									}

									@include mat-pseudo-checkbox();
								}
							}
						}
					}
				}
			}

			media-center-home-sidebar {
				button.add-folder,
				button.add-file,
				button.move-item,
				button.delete-item {
					border: solid 2px $border-button;
					background-color: #fff;
					color: #000;
					mat-icon.mat-icon {
						display: none;
					}

					label {
						font-weight: 500;
					}
				}
			}
		}
	}
}