.compatibility-rules {
	mat-icon,
	.mat-icon {
		display: inline-flex;
		align-items: center;
		width: 40px;
		height: 40px;
		justify-content: center;
		margin: 0;
		font-size: 16px;
		padding: 0;
		flex-shrink: 0;

		svg {
			width: 16px;
			height: 16px;
			font-size: 16px;
			line-height: 16px;
		}

		&.rounded {
			border-radius: 50%;
		}

		&.mat-badge-medium {
			span.mat-badge-content {
				min-width: 22px !important;
				max-width: 38px !important;
				width: auto !important;
				border-radius: 22px !important;
				padding: 0 3px;
			}
		}
	}

	mat-icon.bordered,
	.mat-icon.bordered {
		border: 1px solid $border-gray;
		color: $text-gray-light;
		fill: $text-gray-light;
	}

	mat-icon.filled,
	.mat-icon.filled {
		background-color: $primary;
		color: #fff;
		fill: #fff;
		border: 0;
	}

	mat-icon.mat-icon.dark-color {
		color: $text-gray;
	}
}