.michelangelo-theme {
	mat-list {
		.mat-subheader {
			margin: 0 !important;
			padding: 0 !important;
			color: #000 !important;
			font-weight: 400 !important;
			&::first-letter {
				text-transform: uppercase !important;
			}

			span::first-letter {
				text-transform: uppercase !important;
			}
		}

		mat-list-item {
			span.mat-list-item-content {
				padding: 0 !important;
				font-size: 0.93rem;
			}

			.mat-list-text {
				padding: 0 !important;
			}

			mat-icon.mat-icon.folder {
				margin-right: 5px;

				@include mat-icon-custom(16px, 14px);
			}

			mat-icon.mat-icon.folder {
				@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-folder-2.svg');
			}

			&:hover {
				background-color: inherit !important;
			}
		}

		+ p.subtitle {
			margin: 20px 0 0 0;
			&::first-letter {
				text-transform: uppercase;
			}
		}
	}
}