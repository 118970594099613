.michelangelo-theme {
	/**
    /* formattazione componente td-data-table
    /* prevede principalmente la modifica delle icone di ordinamento
    /* e alcuni fix per colori, font e allineamenti
    **/
	td-data-table {
		.td-data-table {
			.td-data-table-cell {
				font-family: 'Roboto';
				font-size: 1rem;
				color: $text-gray;
			}

			.td-data-table-head {
				.td-data-table-column-row {
					.td-data-table-column,
					.td-data-table-column.mat-clickable.mat-sortable {
						.td-data-table-heading {
							color: $text-gray-light;
							font-family: 'Roboto';
							font-size: 0.93rem;
							font-weight: 400;

							.mat-icon.td-data-table-sort-icon.material-icons {

								@include mat-icon-custom(8px, 8px);
								margin: 0;
								left: -20px;
								@include mat-icon-custom-url("/assets/common/img/custom-icon/icon-trend-up.svg");
							}
						}
					}

					.td-data-table-column.mat-clickable.mat-sortable:not(.mat-numeric) {
						.td-data-table-heading {
							.mat-icon.td-data-table-sort-icon.material-icons {
								left: inherit;
								right: -20px;
							}
						}
					}
				}
			}

			tbody.td-data-table-body {
				tr:hover {
					background-color: inherit;
				}
			}
		}
	}
}
