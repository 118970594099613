.compatibility-rules {
	attachment-list-manager {

		mat-icon.mat-icon {
			width: 30px;
			height: 30px;
			font-size: 25px;
		}

		attachment-resolver {
			drop-area-file {
				display: block;
			}
			.file-selected-container  {
				padding: 0 10px;
				margin-top: 10px !important;
				.file-selected {
					display: flex;
					align-items: center;
					margin: 0 !important;
					padding: 0 !important;
				}
				&:nth-of-type(even) {
					background-color: inherit;
				}
			}
		}
	}
}