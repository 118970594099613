.compatibility-rules {
	/** regole per la formattazione delle dimensioni del mat-dialog company-info
		/* in authentication-wrapper e della dialogo note-change in article-table-wrapper
		**/

	.cdk-overlay-container {
		.cdk-overlay-pane.michelangelo-theme-dialog,
		.cdk-overlay-pane.company-info,
		.cdk-overlay-pane.note-change,
		.cdk-overlay-pane.image-gallery {
			max-width: 60% !important;
			width: 60% !important;



			&.dialog-fullscreen {
				max-width: none !important;
				width: 100vw !important;
				height: 100vh !important;
			}
			&.dialog-medium {
				max-width: 80% !important;
				width: 60% !important;
				height: auto !important;
			}
			&.dialog-normal {
				width: 500px !important;
				height: auto !important;
			}

			&.dialog-small {
				width: 500px !important;
				height: auto !important;
			}

		}
	}

	mat-dialog-wrapper {
		ng-scrollbar {
			.ng-scrollbar-wrapper {
				top: -10px !important;
			}
		}
	}


	.cdk-overlay-container {
		.cdk-overlay-pane.michelangelo-theme-dialog {
			mat-dialog-container.mat-dialog-container {

				dialog-confirm {
					mat-dialog-wrapper {
						> * {
							margin-right: 10px;
							margin-left: 10px;
						}

						mat-dialog-actions {
							margin-right: 10px !important;
							margin-left: 10px !important;
						}
					}
				}

				mat-dialog-wrapper {
					div.group-margin {
						margin-right: 10px;
						margin-left: 10px;
					}
					.dialog__header {
						margin-right: 10px;
						margin-left: 10px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1439px) {
	.michelangelo-theme {
		/** regole per la formattazione delle dimensioni del mat-dialog company-info
        /* in authentication-wrapper
        **/
		.cdk-overlay-container {
			.cdk-overlay-pane.michelangelo-theme-dialog,
			.cdk-overlay-pane.company-info,
			.cdk-overlay-pane.note-change,
			.cdk-overlay-pane.image-gallery {
				max-width: 80% !important;
				width: 80% !important;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.michelangelo-theme {
		/** regole per la formattazione delle dimensioni del mat-dialog company-info
        /* in authentication-wrapper
        **/
		.cdk-overlay-container {
			.cdk-overlay-pane.michelangelo-theme-dialog,
			.cdk-overlay-pane.company-info,
			.cdk-overlay-pane.note-change,
			.cdk-overlay-pane.image-gallery {
				max-width: 95% !important;
				width: 95% !important;

				&.dialog-medium {
					max-width: 95% !important;
					width: 95% !important;
					height: auto !important;
				}
				&.dialog-normal {
					width: 95% !important;
					height: auto !important;
				}
			}
		}
	}
}