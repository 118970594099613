.compatibility-rules {
	.scroll-to-top .mat-fab {
		width: auto;
		height: auto;

		.mat-button-wrapper {
			padding: 0;
		}
	}

	.mat-fab,
	.mat-mini-fab {
		.mat-button-wrapper {
			padding: 0;
		}
	}

	button.mat-fab {
		box-shadow: none;
	}

	button.mat-fab-button,
	button.mat-fab-button__in-page,
	button.mat-fab-button-section-bottom-right {
		.label {
			display: none;
		}

		mat-icon.mat-icon {
			color: #fff;
		}
	}

	button.full-width {
		width: 100% !important;
		justify-content: center !important;
	}

	button.mat-raised-button,
	button.mat-flat-button {
		span.mat-button-wrapper {
			mat-icon.mat-icon {
				color: #fff;
				margin-left: -15px;

				&.mat-icon-no-color {
					color: inherit;
				}
			}
		}
	}

	.user-button-container.b2c {
		> button span {
			color: rgba(255, 255, 255, 1) !important;
		}

		> button span.opacity-50 {
			opacity: 1 !important;
		}
	}
}

@media screen and (max-width: 991px) {
	.compatibility-rules {
		.user-button-container.b2c {
			> button span {
				color: rgba(0, 0, 0, 1) !important;
			}
		}
	}
}