.compatibility-rules {
	.mat-toolbar-row {
		.mat-select {
			width: auto;
			font-size: 0.93rem;

			.mat-select-trigger {
				gap: 10px;
			}
		}
	}

	.mat-select-panel-wrap {
		div[role="listbox"] {
			mat-form-field.mat-form-field.select-filter-text {
				margin: 10px;
			}
		}
	}
}