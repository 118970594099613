.michelangelo-theme {
	product-stock {
		article-list-structure-wrapper-plain-list {
			td-search-box {
				//margin-top: -80px;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.michelangelo-theme {
		product-stock {
			article-list-structure-wrapper-plain-list {
				td-search-box {
					margin-top: 20px;
					width: 100%;
				}
			}
		}
	}
}