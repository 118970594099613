html.fontsize {
	&--normal {
		font-size: 15px !important;
	}
	&--medium {
		font-size: 17px !important;
	}
	&--big {
		font-size: 19px !important;
	}
}

.michelangelo-theme {
	font-family: 'Roboto', 'Helvetica Neue', sans-serif;
	font-size: 1rem;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	strong {
		font-weight: 500;
	}

	.container {
		.main-content {
			padding: 0;
		}
	}

	table thead tr {
		background-color: #fff;
	}

	.placeholder {
		margin-top: 20px;
	}

	.text-gray-light {
		color: $text-gray-light;
	}

	@include mat-checkbox();

	/** TODO: A TRANSIZIONE COMPLETATA DECOMMENTARE LE REGOLE SEGUENTI **/
	.layout-ctx-private,
	.main-toolbar {
		background-color: $background-main-color !important;
	}

	/** FINE BLOCCO REGOLE TRANSIZIONE **/

	.show-on-michelangelo-theme {
		display: inherit !important;
	}

	.mat-menu-content {
		button {
			span {
				font-weight: 400;
			}

			.mat-ripple {
				display: none;
			}

			&:hover {
				background-color: #fff;
			}
		}
	}

	/** "annullo gli effetti di mt-20 e mb-20. Tali margini servono solo in caso di non applicazione di michelangelo-theme **/
	organization-detail-credit {
		.row-wrapper.mt-20 {
			margin-top: inherit !important;
		}

		.row-wrapper.mb-20 {
			margin-bottom: inherit !important;
		}
	}

	credit-list-wrapper {
		mat-toolbar.pt-20 {
			padding-top: inherit !important;
		}
	}
}

@media screen and (max-width: 991px) {
	.michelangelo-theme {
		.layout-ctx-private {
			.layout-ctx-private-main {
				ng-scrollbar {
					.ng-scrollbar-wrapper {
						.ng-scroll-viewport-wrapper {
							.ng-native-scrollbar-hider.ng-scroll-layer {
								right: calc(var(--native-scrollbar-size) * -1) !important;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1439px) {
	.michelangelo-theme {
		.layout-ctx-private {
			.layout-ctx-private-main {
				ng-scrollbar {
					.ng-scrollbar-wrapper {
						.ng-scroll-viewport-wrapper {
							.ng-native-scrollbar-hider.ng-scroll-layer {
								right: calc(var(--native-scrollbar-size) * -1) !important;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.michelangelo-theme {
		.layout-ctx-private {
			.layout-ctx-private-main {
				.main-content {
					margin: 0 !important;
				}

				ng-scrollbar {
					.ng-scrollbar-wrapper {
						.ng-scroll-viewport-wrapper {
							.ng-native-scrollbar-hider.ng-scroll-layer {
								right: calc(var(--native-scrollbar-size) * -1 + 2px) !important;
							}
						}
					}
				}
			}
		}
	}
}