.michelangelo-theme {
	attachment-list-manager, dialog-upload-file {
		attachment-resolver {
			drop-area-file,
			mc-drop-area-file {
				.drop-area-container,
				.dropzone {
					border-radius: 0px;
					background-color: $light-gray !important;

					mat-icon.mat-icon.material-icons {

						@include mat-icon-custom(24px, 24px);
						@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-upload.svg');
					}
				}
			}

			.delete-button {
				button {
					.mat-button-wrapper {
						mat-icon.mat-icon {
							@include mat-icon-custom();
							@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-trash.svg');
						}
					}
				}
			}

			.file-selected-container {
				mat-icon.mat-icon {
					@include mat-icon-custom();
					@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-attach.svg');
				}

				p {
					margin: 0;
				}
			}
		}

		attachment-list-wrapper {

			td-data-table {
				table {
					thead {
						tr.td-data-table-column-row {
							th.mat-checkbox-column {
								@include mat-checkbox();
							}
						}
					}
					tbody {
						tr:hover,
						tr:focus,
						tr.td-selected {
							background-color: transparent !important;
						}

						tr {
							td.mat-checkbox-cell {
								.td-data-table-cell-content-wrapper {
									width: min-content;
								}

								@include mat-pseudo-checkbox();
							}
						}
					}
				}

				& + button {
					margin-bottom: 0;
				}
			}

			mat-icon.mat-icon.attach_file {
				height: 30px !important;
				@include mat-icon-custom(20px, 20px);
				@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-attach.svg');
			}

			.action {
				button {
					.mat-button-wrapper {
						mat-icon.mat-icon {
							@include mat-icon-custom();
						}

						mat-icon.mat-icon.delete {
							@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-trash.svg');
						}

						mat-icon.mat-icon.download {
							@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-download.svg');
						}
					}
				}
			}
		}
	}

	attachment-resolver {
		drop-area-file-project-instance, drop-area-file {
			.drop-area-container,
			.dropzone {
				border-radius: 0px;
				background-color: $light-gray !important;
				padding: 20px;

				mat-icon.mat-icon.material-icons {

					@include mat-icon-custom(24px, 24px);
					@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-upload.svg');
				}
			}
		}

		.delete-button {
			button {
				.mat-button-wrapper {
					mat-icon.mat-icon {
						@include mat-icon-custom();
						@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-trash.svg');
					}
				}
			}
		}
	}

}