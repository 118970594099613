.michelangelo-theme {
	sidebar-content-wrapper {
		.content-wrapper {
			//margin-bottom: 10px;
			.mat-card {
				border-radius: 10px;
				background-color: #fff; /*$light-gray;*/
				border: solid 1px $border-gray;
				box-shadow: none;
				padding: 29px;
			}
		}
	}
}