.compatibility-rules {
	aside {
		.side-content {
			.content-wrapper {
				.mat-card {
					.mat-divider,
					.mat-divider.mat-divider-horizontal {
						border: 0;
						position: inherit;
						width: 100%;
						margin: 20px 0;
					}

					form {
						display: flex;
						flex-direction: column;
						gap: 5px;
					}

					mat-radio-group.mat-radio-group {
						display: flex;
						gap: 10px;
						flex-direction: column;
					}
				}
				//
				//app-chat-thread {
				//	mat-divider.mat-divider.mat-divider-horizontal {
				//		border-bottom: solid 1px $border-gray!important;
				//		width: calc(100% + 34px) !important;
				//		margin: 20px 0 20px -17px !important;
				//
				//		&:last-of-type {
				//			margin-bottom: 12px !important;
				//		}
				//	}
				//}
			}
		}
	}
}