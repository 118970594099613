.michelangelo-theme {
	process-queue {
		border-radius: 10px;
		box-shadow: 0px 1px 2px rgb(0 0 0 / 15%);
		background-color: #fff;
		div.process-queue {
			mat-card.mat-card {
				padding: 10px !important;
				background-color: transparent !important;
				mat-toolbar.mat-toolbar {
					background-color: transparent !important;
					mat-toolbar-row.mat-toolbar-row {
						background-color: transparent !important;
						color: #000 !important;
						font-size: 1.2rem;
						font-family: 'Roboto';
						height: 50px !important;
					}
				}

				mat-card-content.mat-card-content {
					mat-list.mat-list {
						mat-list-item.mat-list-item {
							div.list-item-wrapper {
								padding: 0 16px;
								mat-spinner {
									width: 20px !important;
									height: 20px !important;
									svg {
										width: 20px !important;
										height: 20px !important;
									}
								}

								button {
									width: 20px !important;
									height: 20px !important;
									background: transparent;
									box-shadow: none;
									mat-icon {
										width: 20px !important;
										height: 20px !important;
										color: #fff !important;
									}
								}
							}

							&:last-of-type {
								border: 0 !important;
							}
						}
					}
				}
			}
		}
	}
}