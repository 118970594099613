.michelangelo-theme {
	/**
    /* formattazione componente td-search-box
    /* prevede principalmente l'aggiunta di un bordo arrotondato al wrapper
    /* l'eliminazione della "sottolineatura (che può essere eliminata tramite un flag, ma preferisco farlo da css per mantenere la compatibilità in caso di non applicazione di michelangelo-theme)
    /* e alcuni fix per colori, font e allineamenti
    **/
	td-search-box {
		margin-bottom: 20px;
		width: 100%;
		.td-search-box {
			border: solid 1px $border-gray;
			border-radius: 5px;

			button {
				.mat-button-wrapper {
					mat-icon {
						font-size: 24px;
					}
				}
			}

			td-search-input {
				margin-left: 0 !important;

				.td-search-input {
					overflow: hidden;
				}
			}

			td-search-input[style*='width: 0%;'] {
				width: 0 !important;
			}

			.mat-form-field {
				margin-top: 3px;
				.mat-form-field-wrapper {
					padding: 0;
					.mat-form-field-underline {
						display: none;
					}
					.mat-form-field-flex {
						.mat-form-field-infix {
							border: 0;

							input + .mat-form-field-label-wrapper label {
								color: $text-gray-light;
							}
						}
					}
				}
			}
		}
	}
}
