.compatibility-rules {
	.charts-group {
		display: flex;
		justify-content: space-between;
		gap: 10px;
		overflow-x: auto;

		chart-wrapper {
			min-width: 200px;
			width: 100%;
			height: auto;
		}
	}
}