.michelangelo-theme {
	/* regole per la formattazione del form-button-bar con la classe actions-buttons
		/* si riferisce ai bottoni di "salvataggio/conferma"  e "cancellazione/annullamento"
		/* posti dopo un form di modifica */



	mat-dialog-actions form-button-bar,
	mat-dialog-actions form-button-bar.actions-buttons,
	form-button-bar.actions-buttons,
	tc-ticket-detail-wrapper form .form-button-bar {
		width: 100%;
		//div.form-button-bar align-right
		div.form-button-bar {
			margin-top: 20px;
			justify-content: space-between;
			gap: 5px;

			button {
				margin: 0;
				span.mat-button-wrapper {
					overflow: inherit !important;
					max-width: max-content !important;
				}
			}
		}


		button.mat-accent,
		button.mat-accent.mat-button-base {
			margin-right: 0px;
			order: 1;
		}

		button.mat-accent.mat-button-disabled,
		button.mat-accent.mat-button-disabled.mat.button-base,
		button.mat-accent.mat-button-base.mat-button-disabled {
			border-color: $border-disabled-button !important;
			color: $border-disabled-button !important;
			background-color: transparent !important;
		}

		button.mat-stroked-button {
			order: 3;
			span.mat-button-wrapper {
				margin: 0;
			}
		}
	}

	tc-ticket-detail-wrapper form .form-button-bar {
		justify-content: space-between;
		flex-direction: row-reverse;
	}
}
