@import 'var';

.dialog-fullscreen {
	max-width: none !important;
	width: 100vw;
	height: 100vh;
}
.dialog-medium {
	max-width: none !important;
	width: 80vw;
	height: auto;
}
.dialog-normal {
	width: 500px;
	height: auto;
}

dialog-action-list {
	.mat-fab-button {
		position: absolute !important;
		bottom: 1rem;
		right: 1rem;
	}
	@media (min-width: 1024px) {
		.mat-fab-button {
			bottom: 1.5rem;
			right: 1.5rem;
		}
	}
	@include sectionBottomPositionGenerator('mat-fab-button');
}
