/*
*  Theme name: Customer theme
*  This file contains variables different from var-base.scss
*/

@import '../var-base';

/*
*  override START
*/
$primary: #000000;
$accent: #ff4400;
$secondary: $accent;

$background-main-color: #f9f9f9;

/*
* override new layout
*/

/*
*  extend START
*/

/*
*  extend END
*/

@import '../../abstract/abstract';
@import '@saep-ict/angular-core/src/styles/var-base-process';

/*
*  EUROITALIA B2B | START
*/

@import '~@angular/material/theming';

/* Set variables */
// TODO: verificare utilità delle seguenti, per ora commentate perchè in conflitto con quelle di su
// $primary: #000000;
// $accent: #fb4701;

/* Don't set variables */
$light: #fafafa;
$secondary: #888888;
$dark: #2b2b2b;
$gray: #9ca0a3;
// $light-gray: #f2f4f8;

$warn: #ff3333;
$alert: #ffcc00;
$success: #339933;
$info: #3399cc;

$color-green: rgb(76, 175, 80);
$color-green-dark: rgb(57, 131, 60);
$color-orange: rgb(255, 152, 0);
$color-orange-dark: rgb(255, 131, 0);
$color-yellow: rgb(253, 194, 2);
$color-red: rgb(244, 67, 54);
$color-blue-light: rgb(153, 220, 255);

$chips-red: #F46;
$chips-green: #5DB;
$chips-yellow: #FC0;
$chips-orange: $color-orange;
$chips-grey: #CCC;

$alert-button-content-color: $light;
$warn-button-content-color: $dark;

/* set black or white based on lightness of the color */
@function set-contrast($color) {
	@if (lightness($color) > 52) {
		@return $dark; // Lighter backgorund, return dark color
	} @else {
		@return $light; // Darker background, return light color
	}
}

/* Create color palette in a map */
@function createPaletteColore($color, $name) {
	$name: (
		50: lighten($color, 52%),
		100: lighten($color, 37%),
		200: lighten($color, 26%),
		300: lighten($color, 12%),
		400: lighten($color, 6%),
		500: $color,
		600: darken($color, 6%),
		700: darken($color, 12%),
		800: darken($color, 18%),
		900: darken($color, 24%),
		contrast: (
			50: set-contrast(lighten($color, 52%)),
			100: set-contrast(lighten($color, 37%)),
			200: set-contrast(lighten($color, 26%)),
			300: set-contrast(lighten($color, 12%)),
			400: set-contrast(lighten($color, 6%)),
			500: set-contrast($color),
			600: set-contrast(darken($color, 6%)),
			700: set-contrast(darken($color, 12%)),
			800: set-contrast(darken($color, 18%)),
			900: set-contrast(darken($color, 24%))
		)
	);

	@return $name;
}

/* Create maps of color palette */
$mat-primary: createPaletteColore($primary, 'mat-primary');
$mat-accent: createPaletteColore($accent, 'mat-accent');
$mat-secondary: createPaletteColore($secondary, 'mat-secondary');
$mat-warn: createPaletteColore($warn, 'mat-warn');
$mat-alert: createPaletteColore($alert, 'mat-alert');
$mat-success: createPaletteColore($success, 'mat-success');
$mat-info: createPaletteColore($info, 'mat-info');

$mat-gray: (
	50: lighten($gray, 52%),
	100: lighten($gray, 37%),
	200: lighten($gray, 26%),
	300: lighten($gray, 12%),
	400: lighten($gray, 6%),
	500: $gray,
	600: darken($gray, 6%),
	700: darken($gray, 12%),
	800: darken($gray, 18%),
	900: darken($gray, 24%),
	contrast: (
		50: set-contrast(lighten($gray, 52%)),
		100: set-contrast(lighten($gray, 37%)),
		200: set-contrast(lighten($gray, 26%)),
		300: set-contrast(lighten($gray, 12%)),
		400: set-contrast(lighten($gray, 6%)),
		500: $light,
		600: set-contrast(darken($gray, 6%)),
		700: set-contrast(darken($gray, 12%)),
		800: set-contrast(darken($gray, 18%)),
		900: set-contrast(darken($gray, 24%))
	)
);

$disableGray: rgba(0, 0, 0, 0.12);

$width__sidenav: 256px;
$height__main-toolbar: 80px;

$xs: 'screen and (min-width: 0px) and (max-width: 599px)';
$sm: 'screen and (min-width: 600px) and (max-width: 959px)';
$md: 'screen and (min-width: 960px) and (max-width: 1279px)';
$lg: 'screen and (min-width: 1280px) and (max-width: 1919px)';
$xl: 'screen and (min-width: 1920px) and (max-width: 5000px)';

// Scala
$base: 16px;
$gutter: $base;

/*
4px ( * 0.25)
8px ( * 0.5)
12px (* 0.75)
24px (16 * 1.5)
32px (16 * 2)
*/

// Padding form wrapper
$ptb-16: $base;
$prl-32: $base * 2;

/*
*  EUROITALIA B2B | END
*/
