.compatibility-rules {
	news-detail {
		form.michelangelo-theme {
			margin-top: 40px;
		}
		form-button-bar {
			display: block;
			margin-top: 40px;
			div.form-button-bar {
				margin-top: 25px;
				padding-top: 25px;
			}
		}
	}
}