ngx-gallery {
	&.ngx-gallery {
		display: block;
	}
	.ngx-gallery-icon {
		.ngx-gallery-icon-content {
			width: 40px;
			height: 40px;
			background-color: black;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;

			&::before {
				position: absolute;
				text-align: center;
				background-color: #fff;
				display: inline-block;
				-webkit-font-smoothing: antialiased;
				width: 16px;
				height: 14px;
				font-weight: 900;
				content: '';
				-webkit-mask-size: contain;
				-webkit-mask-repeat: no-repeat;
				-webkit-mask-position: center;
				mask-size: contain;
				mask-repeat: no-repeat;
				mask-position: center;
			}
		}
		//background: red;
	}
	// queste due classi sono state configurate solo per l'istanza presente in
	// frontend/src/app/widget/dialog/dialog-image-gallery/dialog-image-gallery.component.ts
	.arrowPrevIcon,
	.fa-arrow-circle-left {
		&::before {
			-webkit-mask-image: url('/assets/common/img/custom-icon/icon-chevron-left.svg');
			mask-image: url('/assets/common/img/custom-icon/icon-chevron-left..svg');
		}
	}
	.arrowNextIcon,
	.fa-arrow-circle-right {
		&::before {
			-webkit-mask-image: url('/assets/common/img/custom-icon/icon-chevron-right.svg');
			mask-image: url('/assets/common/img/custom-icon/icon-chevron-right.svg');
		}
	}
}
