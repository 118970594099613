.compatibility-rules {
	media-center {
		mc-media-center {
			media-center-home-sidebar {
				button.bg-alert {
					.mat-button-wrapper {
						mat-icon.mat-icon {
							color: $alert-button-content-color !important;
						}
					}
				}

				button.mat-warn {
					.mat-button-wrapper {
						mat-icon.mat-icon {
							color: $warn-button-content-color !important;
						}
					}
				}

				button.add-folder {
					.mat-button-wrapper {
						mat-icon.mat-icon {
							color: $primary !important;
						}
					}
				}
			}
		}
	}
}