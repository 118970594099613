.michelangelo-theme {
	/** override order-detail-aside-help in sidebar **/

	order-detail-aside-help {
		sidebar-content-wrapper {
			button {
				padding: 0;
				margin: 0;
				border-bottom: solid 1px $border-gray;
				mat-icon.mat-icon {
					display: none;
				}

				.mat-ripple {
					display: none;
				}

				&:hover {
					background-color: transparent !important;
				}

				&:last-of-type {
					border-bottom: 0;
				}
			}
		}
	}

	/** nuove icone in actions-menu in order-detail **/

	.order-detail-actions-menu-buttons {
		button {
			padding: 0 10px;
			&:hover {
				background-color: transparent;
			}
			mat-icon.mat-icon + span {
				font-weight: 400;
			}
			mat-icon.mat-icon.copy,
			mat-icon.mat-icon.delete,
			mat-icon.mat-icon.edit,
			mat-icon.mat-icon.pdf,
			mat-icon.mat-icon.upload,
			mat-icon.mat-icon.download {
				margin-right: 5px;

				@include mat-icon-custom(16px, 14px);
			}

			mat-icon.mat-icon.delete {
				@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-trash.svg');
			}

			mat-icon.mat-icon.edit {
				@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-pencil.svg');
			}

			mat-icon.mat-icon.copy {
				@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-content_copy.svg');
			}

			mat-icon.mat-icon.pdf {
				@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-picture_as_pdf.svg');
			}

			mat-icon.mat-icon.upload {
				@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-upload.svg');
				&::before {
					height: 100%;
					width: 20px;
				}
			}

			mat-icon.mat-icon.download {
				@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-download.svg');
			}
		}
	}

	app-order-detail-catalog,
	app-order-detail-checkout {
		> h3 {
			margin-top: 0 !important;
		}
		article-list-structure-wrapper-accordion {
			td-search-box {
				width: 100% !important;
				margin: 20px 0 !important;
			}
		}

		article-list-structure-wrapper-plain-list {
			mat-toolbar {
				margin: 20px 0 !important;
				td-search-box {
					width: 100% !important;
				}
			}
		}

		article-list-structure-wrapper-accordion,
		article-list-structure-wrapper-plain-list,
		article-list-structure-wrapper-category-tree {
			mat-accordion {
				mat-expansion-panel {
					box-shadow: none !important;
					mat-expansion-panel-header {
						mat-panel-title {
							font-weight: 500;
						}
					}

					.mat-expansion-panel-content {
						.mat-expansion-panel-body {
							font-size: 1rem;
							font-weight: 500;

							h3 {
								margin-bottom: 0 !important;
							}
						}
					}
				}

				&:last-of-type:not(:first-of-type) {
					mat-expansion-panel {
						border-bottom: 0;
					}
				}
			}
		}
	}

	app-order-detail-checkout {
		.container-fluid {
			h3 {
				margin-top: 0px !important;
			}
		}

		article-list-category-tree-recursion-order {
			.mat-h1 {
				background-color: transparent !important;
				padding: 0 !important;
				margin: 0 !important;
				color: #000 !important;
			}

			.mat-h2 {
				background-color: transparent !important;
				padding: 0 !important;
				margin: 20px 0 !important;
				color: #000 !important;
			}

			.mat-h3 {
				margin-top: 20px;
			}
		}
	}

	category-tab-list {
		margin-top: 0 !important;
		.TAB {
			margin-top: 15px !important;
			div.tab-wrapper {
				border-bottom: 0 !important;
				div {
					padding: 0 0 7px 0 !important;
					margin-right: 40px !important;
					font-size: 1rem !important;
					font-weight: 400 !important;
					height: 30px !important;
					&.active {
						border-bottom: solid 3px $border-button !important;
					}
				}
			}
		}

		.SELECT {
			mat-form-field.mat-form-field {
				.mat-form-field-wrapper {
					padding: 4px 10px !important;
				}
			}
		}
	}
}