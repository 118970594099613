.compatibility-rules {
	.mat-card.autoheight {
		height: 100%;
	}

	.mat-card {
		.mat-card {
			box-shadow: none;
			padding: 0;
		}
	}



	mat-card.note-card {
		background-color: transparent;
		margin-top: -20px;
		padding: 40px 20px 20px 20px;
		border-top: 0;
		margin-bottom: 20px;
		box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
		0px 3px 3px 0px rgb(0 0 0 / 12%);
		height: auto;

		.mat-card-title {
			font-size: 1rem;
			font-weight: 400;
			margin-bottom: 20px;
		}

		.mat-card-content {
			font-size: 1rem;
			font-weight: 400;
			border-radius: 5px;
			border: solid 1px $border-gray;
			padding: 20px;
			font-size: 0.93rem;
		}
	}

	mat-card {
		.not-visible {
			display: none;
		}
	}
}