/*
*  This is the main file that contains only variables
*
*  1.0 - Colors
*  2.0 - Size
*  3.0 - Typography
*  4.0 - Maps
*/

/*** 1.0 - Colors ***/

// Main palette - Fallback (ma solitamente passati dal progetto parent)
$primary: #6002ee;
$accent: #90ee02;
$secondary: #888888;

// Light and dark
$light: #fafafa;
$dark: #2b2b2b;
$gray: #999999;
$white: #ffffff;

// Semantic colors
$warn: #ff3333;
$alert: #ffcc00;
$success: #339933;
$info: #3399cc;

/*** 2.0 - Size ***/
$width__sidenav: 256px;
$height__main-toolbar: 80px;

// Scala
$base: 16px;
$gutter: $base;

// Padding form wrapper
$ptb-16: $base;
$prl-32: $base * 2;

$font-family-name: 'Roboto, "Helvetica Neue", sans-serif';

/*** 3.0 - Typography ***/

$font-size-xl-map: (
	null: (
		2.5em,
		1.3
	),
	sm: (
		3rem,
		1.4
	)
);
$font-size-lg-map: (
	null: (
		1.3rem,
		1
	)
);
$font-size-default-map: (
	null: (
		1rem,
		1.2
	)
);
$font-size-sm-map: (
	null: (
		0.9rem,
		1.2
	)
);
$font-size-xs-map: (
	null: (
		0.6rem,
		1.2
	)
);

// table
$font-size-td-data-table: $font-size-sm-map;

// card
$font-size-card-title: $font-size-lg-map;
$font-size-card-subtitle: $font-size-sm-map;
$font-size-card-number-big: $font-size-xl-map;
$font-size-card-number-medium: $font-size-lg-map;

// breadcrumb
$font-size-breadcrumb-path: $font-size-sm-map;

// tab wrapper
$font-size-tab-wrapper-label: (
	null: (
		1rem,
		1.2
	)
);

// chat-thread
$disableGray: rgba(0, 0, 0, 0.12);

/*** 4.0 - Maps ***/

/// @link _media-queries.scss
/// @link _mixins.scss
$map-breakpoints: (
	xs: 576px,
	sm: 768px,
	md: 992px,
	lg: 1200px
);

/// @link _mixins.scss
$map-positioning: (
	t: 'top',
	r: 'right',
	b: 'bottom',
	l: 'left'
);


/*
* variabili settate per garantire la compatibilità con gli stili del tema di override
*/

$border-gray: #ebebeb;
$text-gray: #333333;
$icon-gray: #999999;
