.michelangelo-theme {
	button {
		height: 40px;
		font-size: 1rem;
		font-weight: 500;
		color: $primary-text-color;
		display: inline-flex;
		align-items: center;
		background-color: transparent;
		cursor: pointer;
		min-width: fit-content;
		border: none;

		.mat-button-wrapper {
			display: flex;
			line-height: inherit;
			align-items: center;
		}

		svg.mat-calendar-arrow {
			margin: auto 0 auto 5px;
		}

		.mat-button-ripple,
		.mat-button-focus-overlay {
			display: none;
		}

		&:active {
			box-shadow: none !important;
		}
	}

	button.mat-button-basic {
		padding-left: 0;
		padding-right: 0;
		color: $primary;;
	}

	button:not([disabled]):hover {
		opacity: 0.75;
	}

	.user-button-container.b2c {
		> button span {
			color: rgba(255, 255, 255, 1) !important;
		}

		> button span.opacity-50 {
			opacity: 1 !important;
		}
	}

	button.full-width {
		width: 100%;
		justify-content: center;
	}

	button.bordered,
	button.mat-stroked-button {
		border-radius: 20px;
		border: solid 2px $primary;
		padding: 10px 20px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		background-color: #fff;
		color: $primary;

		.mat-button-wrapper {
			margin: 0px;
		}
	}

	button.bordered.with-icon,
	button.mat-stroked-button.with-icon {
		.mat-button-wrapper {
			margin: 0;

			mat-icon.mat-icon {
				width: 36px;
				fill: $primary;
			}
		}

		&.no-label {
			padding-left: 0;
			padding-right: 0;
		}
	}

	button.mat-raised-button,
	button.mat-flat-button {
		border-radius: 20px;
		border: 0;
		padding: 10px 20px;
		box-shadow: none;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		background-color: $primary;
		color: #fff;

		span.mat-button-wrapper {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			mat-icon.mat-icon {
				color: #fff;
				margin-left: -20px;
			}
		}
	}

	button.disabled {
		border-color: $border-disabled-button;
		color: $border-disabled-button;
	}

	button.mat-fab {
		width: auto;
	}

	button.mat-fab-button,
	button.mat-fab-button__in-page,
	button.mat-fab-button-section-bottom-right {
		width: auto;
		border-radius: 20px;
		z-index: 5;

		.label {
			display: block;
			margin-right: 20px;
			line-height: 40px;
		}

		mat-icon.mat-icon {
			color: #fff;
		}
	}


	button.mat-accent,
	button.mat-accent.mat-button-base {
		border: solid 2px $primary !important;
		background-color: $primary !important;
		color: #fff !important;
	}

	button.mat-accent.mat-button-disabled,
	button.mat-accent.mat-button-disabled.mat.button-base,
	button.mat-accent.mat-button-base.mat-button-disabled {
		border-color: $border-disabled-button !important;
		color: $border-disabled-button !important;
		background-color: transparent !important;
	}
}

@media screen and (max-width: 991px) {
	.michelangelo-theme {
		.user-button-container.b2c {
			> button span {
				color: rgba(0, 0, 0, 1) !important;
			}
		}



		button.mat-fab-button,
		button.mat-fab-button,
		button.mat-fab-button__in-page,
		button.mat-fab-button-section-bottom-right {
			.label {
				display: none;
			}
		}

	}
}