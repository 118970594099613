.compatibility-rules {
	.catalog-article-table-wrapper {
		td-search-box {
			width: 100%;
		}
		td-data-table:not(.articleColumnMapB2bCatalog) {
			/**
			/* blocco di regole per il settagio della larghezza delle colonne
			/* per ovviare alla impossibilità di settare le alrghezze in article-column-map-b2b-catalog.constant.ts
			/* TO-DO: da contestualizzare tramite classe wrapper identificativa del contesto b2b
			**/
			thead,
			tbody {
				tr {
					th:nth-child(1),
					td:nth-child(1) {
						width: 90px !important;
						max-width: 90px !important;
						min-width: 90px !important;
					}
					th:nth-child(3),
					td:nth-child(3) {
						width: 180px !important;
						max-width: 180px !important;
						min-width: 180px !important;
					}
					th:nth-child(4),
					td:nth-child(4),
					th:nth-child(5),
					td:nth-child(5) {
						width: 100px !important;
						max-width: 100px !important;
						min-width: 100px !important;
					}
					th:nth-child(6),
					td:nth-child(6) {
						width: 120px !important;
						max-width: 120px !important;
						min-width: 120px !important;
					}
					th:nth-child(7),
					td:nth-child(7) {
						width: 50px !important;
						max-width: 50px !important;
						min-width: 50px !important;
					}
					th:nth-child(8),
					td:nth-child(8) {
						width: 120px !important;
						max-width: 120px !important;
						min-width: 120px !important;
					}
				}
			}

			tbody {
				td:nth-child(8) {
					font-weight: 500;
				}
			}
			/** fine blocco settaggio larghezze **/
		}

		td-data-table.articleColumnMapB2bCatalog {
			/**
			/* blocco di regole per il settagio della larghezza delle colonne
			/* per ovviare alla impossibilità di settare le alrghezze in article-column-map-b2b-catalog.constant.ts
			/* TO-DO: da contestualizzare tramite classe wrapper identificativa del contesto b2b
			**/
			thead,
			tbody {
				tr {
					th:nth-child(3),
					td:nth-child(3) {
						width: 90px !important;
						max-width: 90px !important;
						min-width: 90px !important;
					}
					th:nth-child(5),
					td:nth-child(5) {
						width: 180px !important;
						max-width: 180px !important;
						min-width: 180px !important;
					}
					th:nth-child(6),
					td:nth-child(6),
					th:nth-child(7),
					td:nth-child(7) {
						width: 100px !important;
						max-width: 100px !important;
						min-width: 100px !important;
					}
					th:nth-child(8),
					td:nth-child(8) {
						width: 120px !important;
						max-width: 120px !important;
						min-width: 120px !important;
					}
					th:nth-child(9),
					td:nth-child(9) {
						width: 50px !important;
						max-width: 50px !important;
						min-width: 50px !important;
					}
					th:nth-child(10),
					td:nth-child(10) {
						width: 120px !important;
						max-width: 120px !important;
						min-width: 120px !important;
					}
				}
			}

			tbody {
				td:nth-child(10) {
					font-weight: 500;
				}
			}

			article-change-input {
				span {
					margin-top: 10px;
				}
			}
			/** fine blocco settaggio larghezze **/
		}
	}

	.checkout-article-table-wrapper {
		td-search-box {
			width: 100%;
		}
		td-data-table {
			/**
			/* blocco di regole per il settagio della larghezza delle colonne
			/* per ovviare alla impossibilità di settare le alrghezze in article-column-map-b2b-catalog.constant.ts
			/* TO-DO: da contestualizzare tramite classe wrapper identificativa del contesto b2b
			**/
			thead,
			tbody {
				tr {
					th:nth-child(2),
					td:nth-child(2) {
						width: 90px !important;
						max-width: 90px !important;
						min-width: 90px !important;
					}
					th:nth-child(4),
					td:nth-child(4) {
						width: 180px !important;
						max-width: 180px !important;
						min-width: 180px !important;
					}
					th:nth-child(5),
					td:nth-child(5),
					th:nth-child(6),
					td:nth-child(6) {
						width: 100px !important;
						max-width: 100px !important;
						min-width: 100px !important;
					}
					th:nth-child(7),
					td:nth-child(7) {
						width: 120px !important;
						max-width: 120px !important;
						min-width: 120px !important;
					}
				}
			}

			tbody {
				td:nth-child(7) {
					font-weight: 500;
				}
			}

			article-change-input {
				span {
					margin-top: 10px;
				}
			}
			/** fine blocco settaggio larghezze **/
		}
	}

	article-list-structure-backoffice-forecast-wrapper-accordion {
		mat-accordion.mat-accordion {
			mat-expansion-panel.mat-expansion-panel {
				.mat-expansion-panel-content {
					.mat-expansion-panel-body {
						padding-top: 0;
						.forecast-wrapper {
							.list-row {
								padding-top: 10px;
							}

							.chart-wrapper {
								padding-top: 10px;
							}
						}
					}
				}
			}
		}
	}
}