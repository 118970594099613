/*
*  This is the list of all libraries-overrides
*/

@import 'bootstrap/grid';

@import 'covalent/td-data-table';
@import 'covalent/td-paging-bar';

@import 'material-design/mat-accordion';
@import 'material-design/mat-button';
@import 'material-design/mat-card';
@import 'material-design/mat-dialog';
@import 'material-design/mat-drawer-side';
@import 'material-design/mat-fab-button';
@import 'material-design/mat-form-field';
@import 'material-design/mat-label';
@import 'material-design/mat-ripple';
@import 'material-design/mat-tab-group';
@import 'material-design/mat-toolbar';
@import 'material-design/mat-tooltip';
@import 'material-design/mat-icon';
@import 'material-design/mat-paginator';
@import 'material-design/mat-input';
@import 'material-design/mat-select';

@import 'saep-core/status-bar';

@import 'charts';

@import 'ngx-scrollbar';
@import 'ngx-gallery';
