.michelangelo-theme {
	/**
    /* formattazione componente user-management
    /* prevede principalmente delle uove icone per avatar e status,
    /* l'eliminazione delle freccie laterali nel tab e la modifica delle dimensioni dei suoi item,
    /* l'eliminazione della colonna "avatar" in modalità mobile,
    /* l'eliminazione della select e ralitiva label per indicare il numero di elementi per pagine,
    /* l'allineamento a dx della paginazione
    /* e alcuni fix per colori, font e allineamenti
    **/
	user-management {
		mat-card.mat-card {
			mat-toolbar.mat-toolbar {
				//margin-bottom: 20px;
				mat-toolbar-row.mat-toolbar-row {
					flex-wrap: wrap;
					//gap: 20px;
				}
			}
		}

		table.td-data-table {
			thead.td-data-table-head {
				tr.td-data-table-column-row {
					border-bottom: 0;
				}
			}
			tbody.td-data-table-body {
				tr.td-data-table-row {
					td.td-data-table-cell {
						vertical-align: middle;
					}
				}

				tr:hover {
					background-color: inherit;
				}
			}
		}

		div.td-paging-bar {
			text-align: end;
			// > span,
			// > span + mat-select.mat-select {
			//	display: none;
			// }
		}

		mat-icon.mat-icon.avatar--placeholder,
		mat-icon.mat-icon.status-icon {

			@include mat-icon-custom();

		}

		mat-icon.mat-icon.avatar--placeholder {
			@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-person.svg');
		}

		mat-icon.status-icon, mat-icon.status-icon.txt-green  {
			@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-check_circle.svg');
		}

		mat-icon.status-icon.txt-green {
			&::before {
				background-color: #55DDBB;
			}
		}
	}

	user-management-detail .user-detail,
	.user-detail {
		form {
			button.upload_image {
				height: 50px;

				span.mat-button-wrapper {
					line-height: 40px;
				}

				mat-icon.mat-icon.material-icons {
					margin-right: 10px;

					@include mat-icon-custom();
					@include mat-icon-custom-url('/assets/common/img/custom-icon/icon-person.svg');
				}
			}
		}

		form-permission-group-select {
			mat-list-option {
				.mat-ripple {
					display: none;
				}
			}
			mat-list-option:hover,
			mat-list-option:focus {
				background-color: inherit;
			}

			@include mat-pseudo-checkbox();
		}
	}
}

@media screen and (max-width: 767px) {
	.michelangelo-theme {
		user-management {
			mat-card.mat-card {
				mat-toolbar.mat-toolbar {
					mat-toolbar-row.mat-toolbar-row {
						td-search-box {
							width: 100%;
						}
					}
				}
			}
			td-data-table {
				table.td-data-table {
					thead.td-data-table-head {
						tr.td-data-table-column-row {
							th.td-data-table-column:nth-child(2) {
								display: none;
							}
						}
					}

					tbody.td-data-table-body {
						tr {
							td.td-data-table-cell:nth-child(2) {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}