.michelangelo-theme {
	// override material paginator
	pouch-paging-bar {
		display: inline-block;
		margin-top: 20px;

		.pouch-paging-bar {
			display: flex !important;
			justify-content: center;
			min-height: auto;
			padding: 0;
			flex-direction: row-reverse;
			align-items: center;
			> span {
				display: none;
			}


			mat-select.mat-select {
				margin: 0;
				max-width: 90px;
				width: auto !important;
				font-size: 0.93rem;

				border: solid 1px $border-gray;
				padding: 5px 20px;
				border-radius: 5px;
				.mat-select-trigger {
					gap: 10px;
				}

				div.mat-form-field-underline {
					display: none;
				}
			}

		}

		.mat-paginator-navigation-previous {
			order: 1;
			justify-content: center;
		}
		.mat-paginator-navigation-next {
			order: 3;
			justify-content: center;
		}

		.mat-paginator-page-size-label {
			display: none;
		}

		.mat-select-arrow {
			color: $icon-light-gray;
		}

		.mat-paginator-page-size {
			margin:  0 0 0 20px;
		}



	}
}