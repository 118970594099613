@import 'var';

tab-wrapper {
	.header,
	.main {
		margin: 0 0 $gutter 0 !important;
	}

	.header {
		border-bottom: none !important;

		ul li {
			border: 1px solid $dark;
			border-radius: 56px;
			margin-right: 0.5rem;
			opacity: 0.6;
			margin-bottom: calc($gutter / 2);
			text-transform: lowercase !important;

			&.active,
			&:hover {
				background: $primary;
				color: $white;
				border-color: $primary;
			}

			&.active {
				opacity: 1 !important;
			}

			&::after {
				content: none !important;
			}
		}
	}
}
