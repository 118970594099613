@import 'var';

$mediaList: (
	'sm': 576,
	'md': 768,
	'lg': 992,
	'xl': 1200
);

@mixin gridDivider() {
	@each $key, $val in $mediaList {
		.grid-divider-#{$key} {
			margin: $gutter 0 0 0;
			content: '#{map-get($mediaList, $key)}';
		}
		@media (min-width: #{map-get($mediaList, $key)}px) {
			.grid-divider-#{$key} {
				display: none;
			}
		}
	}
}
.layout-ctx-private {
	.container,
	.container-fluid {
		padding: 0 $gutter;

		.row {
			margin: 0 -#{calc($gutter / 2)};

			&:last-of-type {
				margin-bottom: $gutter;
			}

			// definizione margin/padding per la griglia di livello superiore
			@include col($mT: $gutter, $pR: calc($gutter / 2), $pL: calc($gutter / 2));

			.row {
				margin: 0 -#{calc($gutter / 2)};
				@include col($pR: calc($gutter / 2), $pL: calc($gutter / 2));
			}

			// permette di utilizzare nel template le classi grid-divider-... le quali pongono un margine verticale di $gutter tra due colonne
			// es: la classe grid-divider-xl ha margine $gutter fino a divenire display: none a 1200px di larghezza (vedere $mediaList)
			@include gridDivider();
		}

		// definizione margin/padding per la griglia di livelli annidati
		.mat-card {
			.row {
				@include col($mT: $gutter, $pR: $gutter, $pL: $gutter);
			}
		}
	}

	.container {
		max-width: 1980px;
		min-width: 300px;
	}
}
