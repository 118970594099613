.compatibility-rules {
	forecast-input-list {
		.forecast-input-list-wrapper {
			.total {
				top: 0px !important;
				max-width: 45px !important;
			}
		}
	}
}

@media screen and (max-width: 1439px) {
	.compatibility-rules {
		forecast-input-list {
			.forecast-input-list-wrapper {
				.total {
					top: -30px !important;
					max-width: 100px !important;
				}
			}
		}
	}
}


@media screen and (max-width: 767px) {
	.compatibility-rules {
		forecast-input-list {
			.forecast-input-list-wrapper {
				.inputs {
					.column {
						span.prefix {
							display: inline-block !important;
							width: 25px !important;
							text-align: left;
							font-size: 0.8rem;
							color: $text-gray-light;
							margin-right: 5px;
						}

						input {
							width: calc(100% - 25px) !important;
							text-align: right;
							display: inline-block;
						}
					}
				}

				.total {
					top: -30px !important;
					max-width: 70px !important;
				}
			}
		}
	}
}