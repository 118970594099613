.michelangelo-theme {
	.mat-select-panel-wrap {
		.mat-select-panel {
			mat-option.mat-option {
				.mat-option-text {
					.chips-mini {
						> span + span {
							font-weight: 400 !important;
							color: $text-gray;
							font-size: 0.93rem;
							margin-left: 10px !important;
						}
					}
				}
			}
		}
	}
}