@use '@angular/material' as mat;
@import 'var';

/** 
  Effettua l'override di background-color e color sulla base della color mat-palette passata come parametro;
  per aggiungere un colore:
    $colorPalette: color map in formato convenzionato material design
    $name: nome della classe da porre sul tag mat-accordion
*/
@mixin colorCustomizer($colorPalette, $name) {
	&.#{$name} {
		.mat-expansion-panel-header {
			background-color: map-get($colorPalette, 500);
			.mat-expansion-panel-header-title {
				color: mat.get-contrast-color-from-palette($colorPalette, 500);
			}
			.mat-expansion-indicator:after {
				color: mat.get-contrast-color-from-palette($colorPalette, 500);
			}
			// hover, focus
			&.mat-expanded:hover,
			&.mat-expanded:focus {
				background: map-get($colorPalette, 500);
			}
		}
		// hover
		.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']):hover {
			background: map-get($colorPalette, 500);
		}
	}
}

app-root {
	mat-accordion {
		@include colorCustomizer($mat-primary, 'primary');
		@include colorCustomizer($mat-accent, 'accent');
		@include colorCustomizer(mat.$gray-palette, 'gray');

		.mat-expansion-panel-header {
			padding: 0 $gutter;
		}
		.mat-expansion-panel-body {
			padding: $gutter;
		}
	}
}
