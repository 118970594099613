@mixin mat-icon-custom ($width: 16px, $height: 16px) {
	color: transparent !important;
	background-color: transparent;

	&::before {
		position: absolute;
		text-align: center;
		background-color: #ccc;
		display: inline-block;
		-webkit-font-smoothing: antialiased;
		width: $width;
		height: $height;
		font-weight: 900;
		content: '';
		-webkit-mask-size: contain;
		-webkit-mask-repeat: no-repeat;
		-webkit-mask-position: center;
		mask-size: contain;
		mask-repeat: no-repeat;
		mask-position: center;
	}
}

@mixin mat-icon-custom-url ($url) {
	&::before {
		-webkit-mask-image: url($url);
		mask-image: url($url);
	}
}