.michelangelo-theme {
	.mb-30 {
		margin-bottom: 30px !important;
	}

	.m-30 {
		margin: 30px !important;
	}

	.mt-30 {
		margin-top: 30px !important;
	}

	.ml-30 {
		margin-left: 30px !important;
	}

	.mr-30 {
		margin-right: 30px !important;
	}
}
