.compatibility-rules {
	page-with-sidebar {
		.container {
			.main-content {
				mat-card.mat-card {
					page-with-sidebar {
						.container.child {
							gap: 10px;
							aside {
								.side-content {
									sidebar-content-wrapper {
										.content-wrapper {
											mat-card.mat-card {
												padding: 0;
												border: 0;
												border-radius: 0;
											}
										}
									}
								}
							}

							&.top {
								aside {
									.side-content {
										sidebar-content-wrapper {
											.content-wrapper {
												mat-card.mat-card {
													padding: 0 0 20px 0;
													border-bottom: solid 1px $border-divider;
												}
											}
										}
									}
								}
							}

							&.bottom {
								aside {
									.side-content {
										sidebar-content-wrapper {
											.content-wrapper {
												mat-card.mat-card {
													padding: 20px 0 0 0;
													border-top: solid 1px $border-divider;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

sidebar-content-wrapper {
	form {
		.row:last-of-type {
			margin-bottom: 0 !important;

			& + button.mat-button.mat-button-base {
				margin-bottom: 0 !important;
			}
		}
	}
}