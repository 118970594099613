.compatibility-rules {
	organization-detail-destination {
		> .container-fluid {
			padding: 0;

			.row {
				margin: 0 !important;
				.col-12 {
					padding: 0 !important;
					margin: 0 !important;
				}
			}
		}
	}
}