form, form-control-multipurpose {
	padding: 0 !important;
	margin: 0 !important;
	.row {
		margin-right: 0 !important;
		margin-left: 0 !important;
		mat-form-field, mat-slide-toggle {
			margin-right: 10px;
			margin-left: 10px;
		}

		mat-form-field {
			width: -moz-available;          /* WebKit-based browsers will ignore this. */
			width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
			width: fill-available;
		}
	}
}