.michelangelo-theme {
	mat-icon.video_library {
		margin: 0;
		@include mat-icon-custom(20px, 20px);
		@include mat-icon-custom-url('/assets/theme-current/img/custom-icon/icon-video-library.svg');
	}

	mat-icon.mat-icon.movie {
		margin: 0;
		@include mat-icon-custom(20px, 20px);
		@include mat-icon-custom-url('/assets/theme-current/img/custom-icon/icon-movie.svg');
	}

	mat-icon.mat-icon.fullscreen {
		margin: 0;
		@include mat-icon-custom(20px, 20px);
		@include mat-icon-custom-url('/assets/theme-current/img/custom-icon/icon-fullscreen.svg');
	}

	mat-icon.mat-icon.fullscreen_exit {
		margin: 0;
		@include mat-icon-custom(20px, 20px);
		@include mat-icon-custom-url('/assets/theme-current/img/custom-icon/icon-fullscreen_exit.svg');
	}

	mat-icon.mat-icon.account_balance {
		margin: 0;
		@include mat-icon-custom(20px, 20px);
		@include mat-icon-custom-url('/assets/theme-current/img/custom-icon/icon-account-balance.svg');
	}
}
