b2c-catalog {
	.mat-accordion {
		//sidebar style
		.mat-expansion-panel {
			margin: 0rem;
			padding-bottom: 0rem;
			box-shadow: none;
			display: flex;
			flex-direction: column;

			p {
				margin: 0rem;
			}
		}
		.mat-expansion-panel-header,
		.mat-checkbox {
			width: 100%;
			padding: 1rem 0rem;
			border-radius: 0;
		}
		.mat-expansion-panel-header {
			padding: 1rem;
			flex: 1;
		}
		.mat-expansion-panel-body {
			padding: 0rem 1rem 1rem;
		}
		.mat-checkbox-label {
			white-space: initial;
		}
	}
}
