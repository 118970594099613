@import url('https://fonts.googleapis.com/css?family=Nunito:400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700');

.compatibility-rules {
	::-webkit-scrollbar-thumb {
		background: rgba(0, 0, 0, 0.2);
	}

	::-webkit-scrollbar {
		width: 5px;
		border-radius: 5px;
		height: 5px;
	}

	::-webkit-scrollbar-thumb {
		width: 5px;
		height: 5px;
		border-radius: 5px;
	}

	.container {
		.main-content {
			padding: 2px;
		}
	}

	h3 {
		font-size: 1rem;
		font-weight: 500;
	}

	.mat-toolbar-row {
		h3 {
			font-size: 1rem;
			font-weight: 500;
		}
	}

	.mt-10 {
		margin-top: 10px !important;
	}

	.mt-50 {
		margin-top: 50px !important;
	}

	.ml-5 {
		margin-left: 5px !important;
	}

	.show-on-michelangelo-theme {
		display: none;
	}

	td-search-box {
		width: 100%;
	}

	app-order-detail-checkout {
		> h3 {
			margin-top: 40px !important;
		}
	}

	snack-bar-container {
		.mat-simple-snackbar {
			.mat-simple-snackbar-action {
				button {
					span.mat-button-wrapper {
						color: $accent;
					}
				}
			}
		}
	}

	mc-breadcrumb {
		> span {
			&::before {
				background-color: $icon-gray-light !important;
			}
		}
	}

	.placeholder {
		margin-top: 20px !important;
	}

	.text-truncate-two-row {
		display: -webkit-box;
		/*max-width: 400px;*/
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.text-truncate-one-row {
		display: -webkit-box;
		/*max-width: 400px;*/
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}