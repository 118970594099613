.michelangelo-theme {
	.cdk-overlay-container {
		.cdk-overlay-pane.michelangelo-theme-dialog {
			mat-dialog-container.mat-dialog-container {
				overflow: visible !important;
				border-radius: 10px !important;
				padding: 20px !important;

				> * {
					position: relative;

					.dialog__header {
						h2 {
							font-size: 1rem;
						}
						button.mat-icon-button {
							position: absolute;
							right: -70px;
							top: -70px;
							background-color: $text-gray;
							border-radius: 50%;
							mat-icon {
								color: #fff;
							}
						}
					}
				}

				//mat-dialog-actions {
				//	margin: 0;
				//	padding: 0;
				//	height: auto;
				//	min-height: inherit;
				//	display: flex;
				//	justify-content: space-between !important;
				//}

				> dialog-article-select, dialog-upload-file {
					margin: 10px;
					display: block;
				}

				> dialog-context-code-edit {
					mat-dialog-actions {
						margin-left: 10px;
						margin-right: 10px;
					}
				}

				> *:not(dialog-upload-file, dialog-article-select, dialog-contact-detail, dialog-destination-detail, dialog-context-code-edit) {
					mat-form-field.mat-form-field.mat-form-field-type-mat-input:not(.td-search-input-field),
					mat-form-field.mat-form-field-type-mat-date-range-input {
						margin-bottom: 20px;
						div.mat-form-field-wrapper {
							border: solid 1px $border-gray;
							padding: 10px;
							border-radius: 5px;

							div.mat-form-field-flex {
								div.mat-form-field-infix {
									border: 0;
									input.mat-input-element:not(.mat-input-empty, .ng-invalid, .ng-valid, [disabled])
									+ span.mat-form-field-label-wrapper
									label {
										display: none;
									}
								}
							}
						}

						div.mat-form-field-underline {
							display: none;
						}
					}

					mat-dialog-actions,
					.mat-dialog-actions {
						//margin: 0;
						//padding: 0;
						//height: auto;
						//min-height: inherit;
						//display: flex;
						//justify-content: space-between !important;

						button:not(.mat-primary, .mat-accent), button.mat-button-base:not(.mat-primary) {
							border: 0 !important;
							color: $border-disabled-button !important;
							background-color: transparent !important;
							padding-left: 0px !important;

							span.mat-button-wrapper {
								margin: 0;
							}
						}
					}
				}

				.row {
					margin: 0!important;
					//justify-content: space-between;

				}

				form.michelangelo-theme {
					padding: 0;
				}
			}

			p.subtitle {
				&::first-letter {
					text-transform: uppercase;
				}
			}
		}

		.cdk-overlay-pane {
			mat-dialog-container.mat-dialog-container {
				overflow: visible;
				tc-dialog-ticket-detail {
					position: relative;
					div.display-flex button.mat-icon-button {
						position: absolute;
						right: -70px;
						top: -70px;
						background-color: $primary;
						border-radius: 50%;
						mat-icon {
							color: #fff;
						}
					}
				}
			}
		}
	}


	dialog-article-select {
		.mat-toolbar-row,
		.mat-form-field-flex {
			background-color: #fff;
		}

		.mat-paginator {
			display: inline-block;

			.mat-paginator-container {
				justify-content: center;
				min-height: auto;
			}

			// override material paginator page switch
			.mat-paginator-range-label {
				order: inherit;
			}

			.mat-paginator-navigation-previous {
				justify-content: center;
				order: inherit;
			}
			.mat-paginator-navigation-next {
				justify-content: center;
				order: inherit;
			}
		}
	}

	mat-dialog-actions, .mat-dialog-actions:has(.mat-raised-button) {
		margin: 0;
		padding: 0;
		height: auto;
		min-height: inherit;
		display: flex;
		justify-content: space-between !important;

	}
	mat-dialog-actions, .mat-dialog-actions:not(:has(.mat-button)) {
		margin: 0;
		padding: 0;
		height: auto;
		min-height: inherit;
		display: flex;
		justify-content: right !important;
	}
}

@media screen and (max-width: 767px) {
	.michelangelo-theme {
		.cdk-overlay-container {
			.cdk-overlay-pane.michelangelo-theme-dialog {
				mat-dialog-container.mat-dialog-container {
					> * {
						.dialog__header {
							button.mat-icon-button {
								right: -30px;
								top: -80px;
							}
						}
					}
				}
			}
		}

		.cdk-overlay-pane {
			mat-dialog-container.mat-dialog-container {
				tc-dialog-ticket-detail {
					div.display-flex button.mat-icon-button {
						right: -30px !important;
						top: -80px !important;
					}

					mat-dialog-content.mat-dialog-content {
						ng-scrollbar {
							.ng-scrollbar-wrapper {
								.ng-scroll-viewport-wrapper {
									> div {
										right: 0px !important;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}