/*
 * In questa sezione vengono indicate le personalizzazioni da applicare
 * solo allo storefronte e all'area privata (indipendentemente dal tema scelto)
 */

.layout-ctx-public {
	@import 'tab-wrapper';
	@import 'mat-card';
	@import 'mat-sidenav-container';
	@import 'button';
}
