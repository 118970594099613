.michelangelo-theme {
	mat-form-field.mat-form-field.select-filter-text {
		display: block;
		.mat-form-field-wrapper {
			padding: 5px;
			border: solid 1px $border-gray;
			border-radius: 5px;
			.mat-form-field-underline {
				display: none;
			}
			.mat-form-field-flex {
				flex-direction: row-reverse;
				align-items: unset;
				gap: 5px;
				.mat-form-field-infix {
					border: 0;

					input + .mat-form-field-label-wrapper label {
						color: $text-gray-light;
					}
				}
			}

			.mat-form-field-suffix {
				display: flex;
				align-items: center;
				button {
					color: rgba(0, 0, 0, 0.26);
					.mat-button-wrapper {
						mat-icon {
							font-size: 24px !important;
							line-height: 24px;
						}
					}
				}
			}
		}
	}

	/** blocco formattazione form */

	form.michelangelo-theme {
		padding: 0 10px;
	}

	form.michelangelo-theme,
	tc-ticket-detail-wrapper form,
	mat-dialog-container form,
	.mat-select-panel-wrap.michelangelo-theme  {

		mat-form-field.mat-form-field {
			display: block;
		}
		/* regola per l'eliminazione delle label nei campi focused */
		.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
		.mat-form-field-appearance-legacy.mat-form-field-can-float
		.mat-input-server:focus
		+ .mat-form-field-label-wrapper
		.mat-form-field-label {
			display: none;
		}

		/* regola per la formattazione dei campi inpu/textare e dei date-picker */
		mat-form-field.mat-form-field.mat-form-field-type-mat-input:not(.td-search-input-field),
		mat-form-field.mat-form-field-type-mat-date-range-input {
			margin-bottom: 20px;
			div.mat-form-field-wrapper {
				border: solid 1px $border-gray;
				padding: 10px;
				border-radius: 5px;

				div.mat-form-field-flex {
					div.mat-form-field-infix {
						border: 0;
						input.mat-input-element:not(.mat-input-empty, .ng-invalid, .ng-valid, [disabled])
						+ span.mat-form-field-label-wrapper
						label {
							display: none;
						}
					}
				}
			}

			div.mat-form-field-underline {
				display: none;
			}

			@include mat-date-picker-toggle();
		}

		mat-form-field.mat-form-field.mat-form-field-disabled {
			div.mat-form-field-wrapper {
				background-color: $input-disabled-background !important;
			}
		}


		mat-form-field.mat-form-field.mat-form-field-invalid {
			div.mat-form-field-wrapper {
				border-color: #ff3333 !important;
			}
		}



		mat-form-field.mat-form-field.mat-focused.mat-form-field-should-float {
			div.mat-form-field-wrapper {
				div.mat-form-field-flex {
					div.mat-form-field-infix {
						span.mat-form-field-label-wrapper {
							label {
								display: none !important;
							}
						}
					}
				}
			}
		}

		/** regole per la customizzazione delle mat-select */
		mat-form-field.mat-form-field.mat-form-field-type-mat-select {
			margin-bottom: 20px;
			div.mat-form-field-wrapper {
				border: solid 1px $border-gray;
				padding: 10px;
				border-radius: 5px;

				div.mat-form-field-flex {
					div.mat-form-field-infix {
						border: 0;
						mat-select.mat-select:not(.mat-select-empty) + span.mat-form-field-label-wrapper label {
							display: none;
						}
					}
				}
			}

			div.mat-form-field-underline {
				display: none;
			}
		}

		mat-form-field.mat-form-field.mat-focused.mat-form-field-should-float.mat-form-field-type-mat-select {
			div.mat-form-field-wrapper {
				div.mat-form-field-flex {
					div.mat-form-field-infix {
						span.mat-form-field-label-wrapper {
							label {
								display: none;
							}
						}
					}
				}
			}
		}

		mat-slide-toggle.mat-slide-toggle {
			span.mat-slide-toggle-bar {
				height: 22px;
				width: 38px;
				border-radius: 11px;
				background-color: rgba($primary, 0.25);
				//opacity: 0.25;

				span.mat-slide-toggle-thumb-container {
					width: 20px;
					height: 20px;
					top: 2px;
					left: 2px;

					span.mat-slide-toggle-thumb {
						height: 18px;
						width: 18px;
					}

					span.mat-ripple.mat-slide-toggle-ripple {
						display: none;
					}
				}
			}

			&.mat-accent.mat-checked {
				span.mat-slide-toggle-bar {
					background-color: rgba($primary, 0.25) !important;
				}
			}
		}
	}

	mat-form-field.mat-form-field.mat-focused,
	mat-form-field.mat-form-field.mat-form-field-should-float {
		div.mat-form-field-flex {
			div.mat-form-field-infix {
				border: 0;
				input.mat-input-element:not(.mat-input-empty, .ng-invalid, .ng-valid, [disabled])
				+ span.mat-form-field-label-wrapper
				label {
					display: none;
				}
			}
		}
	}

	form, form-control-multipurpose {
		.group-margin {
			mat-divider.mat-divider {
				display: none;
			}
		}
	}
}
