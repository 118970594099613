.michelangelo-theme {
	/** TODO : la classe 'michelangelo-theme' sulla mat-card è applicata solo per motivi di compatibilità. A fine porting si potrà eliminare */
	mat-card.michelangelo-theme {
		padding: 30px;
		height: inherit;
		color: $primary-text-color;
		margin: 1px;
		border-radius: 10px;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
		background-color: #fff;

		.mat-toolbar {
			min-height: auto;
			padding: 0;

			.mat-toolbar-row {
				font-size: 1.46rem;
				padding: 0;
				font-family: 'Nunito', 'Roboto';

				.mat-select {
					width: auto;
					font-size: 0.93rem;
					color: $text-gray-light;
				}

				h3 {
					color: $primary-text-color;
				}
			}
		}

		.mat-card-subtitle {
			font-size: 0.93rem;
			color: $text-gray-light;
			font-family: 'Roboto';
		}

		mat-divider,
		mat-divider.mat-divider,
		.mat-divider {
			border: 0;
			border-top: dashed 1px $border-divider;
			position: inherit;
			width: calc(100% + 60px);
			margin-left: -30px;
		}

		mat-divider.top-spacing,
		mat-divider.mat-divider.top-spacing,
		.mat-divider.top-spacing {
			margin-top: 30px;
		}

		mat-divider.bottom-spacing,
		mat-divider.mat-divider.bottom-spacing,
		.mat-divider.bottom-spacing {
			margin-bottom: 30px;
		}

		mat-divider.both-spacing,
		mat-divider.mat-divider.both-spacing,
		.mat-divider.both-spacing {
			margin-top: 30px;
			margin-bottom: 30px;
		}

		section.closable-panel {
			width: calc(100% + 60px);
			margin-left: -30px;
		}

		mat-card-content.mat-card-content {
			mat-tab-group.mat-tab-group {
				.mat-tab-body-wrapper {
					mat-tab-body.mat-tab-body {
						.mat-tab-body-content {
							section.closable-panel {
								padding-left: 60px;
								padding-right: 60px;
							}
						}
					}
				}
			}
		}

		tab-wrapper {
			.header {
				border-bottom: 0 !important;
				margin-bottom: 30px !important;

				ul {
					li {
						font-size: 1rem !important;
						color: $text-gray !important;
						font-weight: 400 !important;
						padding: 0 !important;
						margin: 0 20px !important;
						opacity: 0.6;
						transition: opacity 0.3s;
					}

					li:first-child {
						margin-left: 0 !important;
					}

					li.active {
						opacity: 1;
					}

					li:hover {
						opacity: 1;
					}
				}
			}
		}

		organization-legal-information {
			.row {
				.info-row {
					align-items: center !important;
					margin: 0 !important;
					padding: 0 10px !important;
					.header {
						margin-bottom: 0 !important;
						border-bottom: solid 1px $border-gray !important;
					}

					.content {
						border-bottom: solid 1px $border-gray !important;
					}
				}
			}
		}
	}

	mat-card.michelangelo-theme.transparent {
		background-color: $background-main-color;

		* {
			background-color: transparent;
		}
	}

	mat-card.michelangelo-theme.note-card {
		background-color: transparent;
		margin-top: -20px;
		padding: 40px 20px 20px 20px;
		box-shadow: 0px 2px 2px rgb(0 0 0 / 15%);

		.mat-card-title {
			font-size: 1rem;
			font-weight: 400;
			margin-bottom: 20px;
		}

		.mat-card-content {
			font-size: 1rem;
			font-weight: 400;
			border-radius: 5px;
			border: solid 1px $border-gray;
			padding: 20px;
			font-size: 0.93rem;
		}
	}
}

@media screen and (max-width: 767px) {
	.michelangelo-theme {
		mat-card.michelangelo-theme {
			padding: 20px;

			section.closable-panel {
				width: calc(100% + 40px);
				margin-left: -20px;
			}

			mat-divider,
			mat-divider.mat-divider,
			.mat-divider {
				border: 0;
				border-top: dashed 1px $border-divider;
				position: inherit;
				width: calc(100% + 40px);
				margin-left: -20px;
			}
		}
	}
}