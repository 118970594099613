.michelangelo-theme {
	forecast-input-list {
		.forecast-input-list-wrapper {
			.inputs {
				input {
					border: solid 1px $border-gray;
					padding: 10px;
					border-radius: 5px;
					width: 70px;
					text-align: right;
				}
			}

			.total {
				top: 9px !important;
				max-width: 45px !important;
			}
		}
	}

	forecast {
		mat-accordion {
			mat-expansion-panel {
				box-shadow: none !important;
				padding: 0 10px;

				mat-expansion-panel-header {
					flex-direction: row-reverse;
					gap: 20px;

					span.mat-expansion-indicator {
						margin-left: 5px;
					}
				}

				&:last-of-type {
					border-bottom: 0;
				}

				.mat-expansion-panel-body {
					margin-bottom: 0px !important;
					article-table-wrapper {
						.article-table-wrapper-base.articleColumnMapB2bForecast {
							.rows-wrapper {
								.content-wrapper {
									&:last-of-type:not(:first-of-type) {
										border-bottom: 0;
									}
								}
							}
						}
					}
				}

				&.mat-expanded {
					background-color: $light-gray;
				}
			}
		}
	}
}

@media screen and (max-width: 1439px) {
	.michelangelo-theme {
		forecast-input-list {
			.forecast-input-list-wrapper {
				.total {
					top: -30px !important;
					max-width: 100px !important;
				}
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.michelangelo-theme {
		forecast-input-list {
			.forecast-input-list-wrapper {
				.total {
					top: -30px !important;
					max-width: 70px !important;
				}
				.inputs {
					.column {
						span.prefix {
							display: inline-block;
							width: 45px !important;
							border: solid 1px $border-gray;
							border-right: 0;
							padding: 10px 10px 10px 10px;
							border-radius: 5px 0 0 5px;
							text-align: left;
							height: 40px;
							font-size: 0.8rem;
							color: $text-gray-light;
							margin-right: 0;
						}

						input {
							border: solid 1px $border-gray;
							padding: 10px 10px 10px 0px;
							border-radius: 0 5px 5px 0;
							border-left: 0;
							width: calc(100% - 45px) !important;
							text-align: right;
							display: inline-block;
							height: 40px;
						}
					}
				}
			}
		}
	}
}